import { default as indexWczPww26SLMeta } from "/vercel/path0/pages/admin/activities/index.vue?macro=true";
import { default as indexC1H0WMJ1pKMeta } from "/vercel/path0/pages/admin/alerts/index.vue?macro=true";
import { default as indexUp45YlNH68Meta } from "/vercel/path0/pages/admin/ratings/index.vue?macro=true";
import { default as edit1EgduzTX9tMeta } from "/vercel/path0/pages/admin/shifts/[id]/edit.vue?macro=true";
import { default as createsCTyF1pAmSMeta } from "/vercel/path0/pages/admin/shifts/create.vue?macro=true";
import { default as indexElKiuOmOrLMeta } from "/vercel/path0/pages/admin/shifts/index.vue?macro=true";
import { default as indexCzYN1x0Dr9Meta } from "/vercel/path0/pages/admin/stats/index.vue?macro=true";
import { default as editOMG8xr3aObMeta } from "/vercel/path0/pages/admin/users/[id]/edit.vue?macro=true";
import { default as indexVslVie2HsrMeta } from "/vercel/path0/pages/admin/users/[id]/index.vue?macro=true";
import { default as createS2atE5vqhoMeta } from "/vercel/path0/pages/admin/users/create.vue?macro=true";
import { default as indexgxZ1ikbUBwMeta } from "/vercel/path0/pages/admin/users/index.vue?macro=true";
import { default as organization_45buttonA7dJrOkRNxMeta } from "/vercel/path0/pages/btn-qr/organization-button.vue?macro=true";
import { default as organization_45qrbPKkmCATGNMeta } from "/vercel/path0/pages/btn-qr/organization-qr.vue?macro=true";
import { default as btn_45qrGfS2sCFheZMeta } from "/vercel/path0/pages/btn-qr.vue?macro=true";
import { default as indexH8ojxYxOKTMeta } from "/vercel/path0/pages/contacts/index.vue?macro=true";
import { default as forgotten_45password6vATTz81FwMeta } from "/vercel/path0/pages/forgotten-password.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91type_939zxLEciV8XMeta } from "/vercel/path0/pages/kalendar/[type].vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as new_45passwordtlTx6shQZhMeta } from "/vercel/path0/pages/new-password.vue?macro=true";
import { default as chatuiej63EEQgMeta } from "/vercel/path0/pages/operator/requests/[id]/chat.vue?macro=true";
import { default as clientBl39c9j6qTMeta } from "/vercel/path0/pages/operator/requests/[id]/detail/client.vue?macro=true";
import { default as historyxemKLobw67Meta } from "/vercel/path0/pages/operator/requests/[id]/detail/history.vue?macro=true";
import { default as indexlT848m1AovMeta } from "/vercel/path0/pages/operator/requests/[id]/detail/index.vue?macro=true";
import { default as detailKGabq0bMC3Meta } from "/vercel/path0/pages/operator/requests/[id]/detail.vue?macro=true";
import { default as indexnbpuxOvUMUMeta } from "/vercel/path0/pages/operator/requests/index.vue?macro=true";
import { default as _91type_93dyfoQGPXG2Meta } from "/vercel/path0/pages/operator/settings/[type].vue?macro=true";
import { default as code_45pushjRWNXnw0nMMeta } from "/vercel/path0/pages/operator/settings/code-push.vue?macro=true";
import { default as settingsY9niOMH8lTMeta } from "/vercel/path0/pages/operator/settings.vue?macro=true";
import { default as editmaCFh0hp7fMeta } from "/vercel/path0/pages/operator/shifts/[id]/edit.vue?macro=true";
import { default as create3SFBEB8UlmMeta } from "/vercel/path0/pages/operator/shifts/create.vue?macro=true";
import { default as indexKopxeBb1Q8Meta } from "/vercel/path0/pages/operator/shifts/index.vue?macro=true";
import { default as historybmvCryRPBpMeta } from "/vercel/path0/pages/operator/users/[id]/history.vue?macro=true";
import { default as indexb5vYMsu5wkMeta } from "/vercel/path0/pages/operator/users/[id]/index.vue?macro=true";
import { default as _91id_93vp2wcoQQySMeta } from "/vercel/path0/pages/operator/users/[id].vue?macro=true";
import { default as clientsqPtet9GLkIMeta } from "/vercel/path0/pages/operator/users/clients.vue?macro=true";
import { default as operatorsCekn0CvH9eMeta } from "/vercel/path0/pages/operator/users/operators.vue?macro=true";
import { default as organizationsyeBjGnoRDHMeta } from "/vercel/path0/pages/operator/users/organizations.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as chat5ptnKUH9RxMeta } from "/vercel/path0/pages/requests/[id]/chat.vue?macro=true";
import { default as editopurr0m6VcMeta } from "/vercel/path0/pages/requests/[id]/edit.vue?macro=true";
import { default as reservedsmaNP8tzI0Meta } from "/vercel/path0/pages/requests/[id]/reserved.vue?macro=true";
import { default as waitingAdl7gszxPXMeta } from "/vercel/path0/pages/requests/[id]/waiting.vue?macro=true";
import { default as createaxMMoyOkTNMeta } from "/vercel/path0/pages/requests/create.vue?macro=true";
import { default as indexfWenljGIIYMeta } from "/vercel/path0/pages/requests/index.vue?macro=true";
import { default as _91type_93RvJNWuMHnSMeta } from "/vercel/path0/pages/settings/[type].vue?macro=true";
import { default as code_45pushqElbF41KaAMeta } from "/vercel/path0/pages/settings/code-push.vue?macro=true";
import { default as employeesjJ4ocjYDWFMeta } from "/vercel/path0/pages/settings/employees.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as indexoa8UIM335lMeta } from "/vercel/path0/pages/support/index.vue?macro=true";
export default [
  {
    name: "admin-activities___cs",
    path: "/admin/activities",
    meta: indexWczPww26SLMeta || {},
    component: () => import("/vercel/path0/pages/admin/activities/index.vue")
  },
  {
    name: "admin-activities___ua",
    path: "/ua/admin/activities",
    meta: indexWczPww26SLMeta || {},
    component: () => import("/vercel/path0/pages/admin/activities/index.vue")
  },
  {
    name: "admin-alerts___cs",
    path: "/admin/alerts",
    meta: indexC1H0WMJ1pKMeta || {},
    component: () => import("/vercel/path0/pages/admin/alerts/index.vue")
  },
  {
    name: "admin-alerts___ua",
    path: "/ua/admin/alerts",
    meta: indexC1H0WMJ1pKMeta || {},
    component: () => import("/vercel/path0/pages/admin/alerts/index.vue")
  },
  {
    name: "admin-ratings___cs",
    path: "/admin/ratings",
    meta: indexUp45YlNH68Meta || {},
    component: () => import("/vercel/path0/pages/admin/ratings/index.vue")
  },
  {
    name: "admin-ratings___ua",
    path: "/ua/admin/ratings",
    meta: indexUp45YlNH68Meta || {},
    component: () => import("/vercel/path0/pages/admin/ratings/index.vue")
  },
  {
    name: "admin-shifts-id-edit___cs",
    path: "/admin/shifts/:id()/edit",
    meta: edit1EgduzTX9tMeta || {},
    component: () => import("/vercel/path0/pages/admin/shifts/[id]/edit.vue")
  },
  {
    name: "admin-shifts-id-edit___ua",
    path: "/ua/admin/shifts/:id()/edit",
    meta: edit1EgduzTX9tMeta || {},
    component: () => import("/vercel/path0/pages/admin/shifts/[id]/edit.vue")
  },
  {
    name: "admin-shifts-create___cs",
    path: "/admin/shifts/create",
    meta: createsCTyF1pAmSMeta || {},
    component: () => import("/vercel/path0/pages/admin/shifts/create.vue")
  },
  {
    name: "admin-shifts-create___ua",
    path: "/ua/admin/shifts/create",
    meta: createsCTyF1pAmSMeta || {},
    component: () => import("/vercel/path0/pages/admin/shifts/create.vue")
  },
  {
    name: "admin-shifts___cs",
    path: "/admin/shifts",
    meta: indexElKiuOmOrLMeta || {},
    component: () => import("/vercel/path0/pages/admin/shifts/index.vue")
  },
  {
    name: "admin-shifts___ua",
    path: "/ua/admin/shifts",
    meta: indexElKiuOmOrLMeta || {},
    component: () => import("/vercel/path0/pages/admin/shifts/index.vue")
  },
  {
    name: "admin-stats___cs",
    path: "/admin/stats",
    meta: indexCzYN1x0Dr9Meta || {},
    component: () => import("/vercel/path0/pages/admin/stats/index.vue")
  },
  {
    name: "admin-stats___ua",
    path: "/ua/admin/stats",
    meta: indexCzYN1x0Dr9Meta || {},
    component: () => import("/vercel/path0/pages/admin/stats/index.vue")
  },
  {
    name: "admin-users-id-edit___cs",
    path: "/admin/users/:id()/edit",
    meta: editOMG8xr3aObMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/[id]/edit.vue")
  },
  {
    name: "admin-users-id-edit___ua",
    path: "/ua/admin/users/:id()/edit",
    meta: editOMG8xr3aObMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/[id]/edit.vue")
  },
  {
    name: "admin-users-id___cs",
    path: "/admin/users/:id()",
    meta: indexVslVie2HsrMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/[id]/index.vue")
  },
  {
    name: "admin-users-id___ua",
    path: "/ua/admin/users/:id()",
    meta: indexVslVie2HsrMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/[id]/index.vue")
  },
  {
    name: "admin-users-create___cs",
    path: "/admin/users/create",
    meta: createS2atE5vqhoMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/create.vue")
  },
  {
    name: "admin-users-create___ua",
    path: "/ua/admin/users/create",
    meta: createS2atE5vqhoMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/create.vue")
  },
  {
    name: "admin-users___cs",
    path: "/admin/users",
    meta: indexgxZ1ikbUBwMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/index.vue")
  },
  {
    name: "admin-users___ua",
    path: "/ua/admin/users",
    meta: indexgxZ1ikbUBwMeta || {},
    component: () => import("/vercel/path0/pages/admin/users/index.vue")
  },
  {
    name: "btn-qr___cs",
    path: "/btn-qr",
    meta: btn_45qrGfS2sCFheZMeta || {},
    component: () => import("/vercel/path0/pages/btn-qr.vue"),
    children: [
  {
    name: "btn-qr-organization-button___cs",
    path: "organization-button",
    meta: organization_45buttonA7dJrOkRNxMeta || {},
    component: () => import("/vercel/path0/pages/btn-qr/organization-button.vue")
  },
  {
    name: "btn-qr-organization-qr___cs",
    path: "organization-qr",
    meta: organization_45qrbPKkmCATGNMeta || {},
    component: () => import("/vercel/path0/pages/btn-qr/organization-qr.vue")
  }
]
  },
  {
    name: "btn-qr___ua",
    path: "/ua/btn-qr",
    meta: btn_45qrGfS2sCFheZMeta || {},
    component: () => import("/vercel/path0/pages/btn-qr.vue"),
    children: [
  {
    name: "btn-qr-organization-button___ua",
    path: "organization-button",
    meta: organization_45buttonA7dJrOkRNxMeta || {},
    component: () => import("/vercel/path0/pages/btn-qr/organization-button.vue")
  },
  {
    name: "btn-qr-organization-qr___ua",
    path: "organization-qr",
    meta: organization_45qrbPKkmCATGNMeta || {},
    component: () => import("/vercel/path0/pages/btn-qr/organization-qr.vue")
  }
]
  },
  {
    name: "contacts___cs",
    path: "/contacts",
    meta: indexH8ojxYxOKTMeta || {},
    component: () => import("/vercel/path0/pages/contacts/index.vue")
  },
  {
    name: "contacts___ua",
    path: "/ua/contacts",
    meta: indexH8ojxYxOKTMeta || {},
    component: () => import("/vercel/path0/pages/contacts/index.vue")
  },
  {
    name: "forgotten-password___cs",
    path: "/forgotten-password",
    meta: forgotten_45password6vATTz81FwMeta || {},
    component: () => import("/vercel/path0/pages/forgotten-password.vue")
  },
  {
    name: "forgotten-password___ua",
    path: "/ua/forgotten-password",
    meta: forgotten_45password6vATTz81FwMeta || {},
    component: () => import("/vercel/path0/pages/forgotten-password.vue")
  },
  {
    name: "index___cs",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___ua",
    path: "/ua",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "kalendar-type___cs",
    path: "/kalendar/:type()",
    meta: _91type_939zxLEciV8XMeta || {},
    component: () => import("/vercel/path0/pages/kalendar/[type].vue")
  },
  {
    name: "kalendar-type___ua",
    path: "/ua/kalendar/:type()",
    meta: _91type_939zxLEciV8XMeta || {},
    component: () => import("/vercel/path0/pages/kalendar/[type].vue")
  },
  {
    name: "login___cs",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "login___ua",
    path: "/ua/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "new-password___cs",
    path: "/new-password",
    meta: new_45passwordtlTx6shQZhMeta || {},
    component: () => import("/vercel/path0/pages/new-password.vue")
  },
  {
    name: "new-password___ua",
    path: "/ua/new-password",
    meta: new_45passwordtlTx6shQZhMeta || {},
    component: () => import("/vercel/path0/pages/new-password.vue")
  },
  {
    name: "operator-requests-id-chat___cs",
    path: "/operator/requests/:id()/chat",
    meta: chatuiej63EEQgMeta || {},
    component: () => import("/vercel/path0/pages/operator/requests/[id]/chat.vue")
  },
  {
    name: "operator-requests-id-chat___ua",
    path: "/ua/operator/requests/:id()/chat",
    meta: chatuiej63EEQgMeta || {},
    component: () => import("/vercel/path0/pages/operator/requests/[id]/chat.vue")
  },
  {
    name: detailKGabq0bMC3Meta?.name,
    path: "/operator/requests/:id()/detail",
    meta: detailKGabq0bMC3Meta || {},
    component: () => import("/vercel/path0/pages/operator/requests/[id]/detail.vue"),
    children: [
  {
    name: "operator-requests-id-detail-client___cs",
    path: "client",
    component: () => import("/vercel/path0/pages/operator/requests/[id]/detail/client.vue")
  },
  {
    name: "operator-requests-id-detail-history___cs",
    path: "history",
    meta: historyxemKLobw67Meta || {},
    component: () => import("/vercel/path0/pages/operator/requests/[id]/detail/history.vue")
  },
  {
    name: "operator-requests-id-detail___cs",
    path: "",
    component: () => import("/vercel/path0/pages/operator/requests/[id]/detail/index.vue")
  }
]
  },
  {
    name: detailKGabq0bMC3Meta?.name,
    path: "/ua/operator/requests/:id()/detail",
    meta: detailKGabq0bMC3Meta || {},
    component: () => import("/vercel/path0/pages/operator/requests/[id]/detail.vue"),
    children: [
  {
    name: "operator-requests-id-detail-client___ua",
    path: "client",
    component: () => import("/vercel/path0/pages/operator/requests/[id]/detail/client.vue")
  },
  {
    name: "operator-requests-id-detail-history___ua",
    path: "history",
    meta: historyxemKLobw67Meta || {},
    component: () => import("/vercel/path0/pages/operator/requests/[id]/detail/history.vue")
  },
  {
    name: "operator-requests-id-detail___ua",
    path: "",
    component: () => import("/vercel/path0/pages/operator/requests/[id]/detail/index.vue")
  }
]
  },
  {
    name: "operator-requests___cs",
    path: "/operator/requests",
    meta: indexnbpuxOvUMUMeta || {},
    component: () => import("/vercel/path0/pages/operator/requests/index.vue")
  },
  {
    name: "operator-requests___ua",
    path: "/ua/operator/requests",
    meta: indexnbpuxOvUMUMeta || {},
    component: () => import("/vercel/path0/pages/operator/requests/index.vue")
  },
  {
    name: "operator-settings___cs",
    path: "/operator/settings",
    meta: settingsY9niOMH8lTMeta || {},
    component: () => import("/vercel/path0/pages/operator/settings.vue"),
    children: [
  {
    name: "operator-settings-type___cs",
    path: ":type()",
    meta: _91type_93dyfoQGPXG2Meta || {},
    component: () => import("/vercel/path0/pages/operator/settings/[type].vue")
  },
  {
    name: "operator-settings-code-push___cs",
    path: "code-push",
    meta: code_45pushjRWNXnw0nMMeta || {},
    component: () => import("/vercel/path0/pages/operator/settings/code-push.vue")
  }
]
  },
  {
    name: "operator-settings___ua",
    path: "/ua/operator/settings",
    meta: settingsY9niOMH8lTMeta || {},
    component: () => import("/vercel/path0/pages/operator/settings.vue"),
    children: [
  {
    name: "operator-settings-type___ua",
    path: ":type()",
    meta: _91type_93dyfoQGPXG2Meta || {},
    component: () => import("/vercel/path0/pages/operator/settings/[type].vue")
  },
  {
    name: "operator-settings-code-push___ua",
    path: "code-push",
    meta: code_45pushjRWNXnw0nMMeta || {},
    component: () => import("/vercel/path0/pages/operator/settings/code-push.vue")
  }
]
  },
  {
    name: "operator-shifts-id-edit___cs",
    path: "/operator/shifts/:id()/edit",
    meta: editmaCFh0hp7fMeta || {},
    component: () => import("/vercel/path0/pages/operator/shifts/[id]/edit.vue")
  },
  {
    name: "operator-shifts-id-edit___ua",
    path: "/ua/operator/shifts/:id()/edit",
    meta: editmaCFh0hp7fMeta || {},
    component: () => import("/vercel/path0/pages/operator/shifts/[id]/edit.vue")
  },
  {
    name: "operator-shifts-create___cs",
    path: "/operator/shifts/create",
    meta: create3SFBEB8UlmMeta || {},
    component: () => import("/vercel/path0/pages/operator/shifts/create.vue")
  },
  {
    name: "operator-shifts-create___ua",
    path: "/ua/operator/shifts/create",
    meta: create3SFBEB8UlmMeta || {},
    component: () => import("/vercel/path0/pages/operator/shifts/create.vue")
  },
  {
    name: "operator-shifts___cs",
    path: "/operator/shifts",
    meta: indexKopxeBb1Q8Meta || {},
    component: () => import("/vercel/path0/pages/operator/shifts/index.vue")
  },
  {
    name: "operator-shifts___ua",
    path: "/ua/operator/shifts",
    meta: indexKopxeBb1Q8Meta || {},
    component: () => import("/vercel/path0/pages/operator/shifts/index.vue")
  },
  {
    name: _91id_93vp2wcoQQySMeta?.name,
    path: "/operator/users/:id()",
    meta: _91id_93vp2wcoQQySMeta || {},
    component: () => import("/vercel/path0/pages/operator/users/[id].vue"),
    children: [
  {
    name: "operator-users-id-history___cs",
    path: "history",
    meta: historybmvCryRPBpMeta || {},
    component: () => import("/vercel/path0/pages/operator/users/[id]/history.vue")
  },
  {
    name: "operator-users-id___cs",
    path: "",
    component: () => import("/vercel/path0/pages/operator/users/[id]/index.vue")
  }
]
  },
  {
    name: _91id_93vp2wcoQQySMeta?.name,
    path: "/ua/operator/users/:id()",
    meta: _91id_93vp2wcoQQySMeta || {},
    component: () => import("/vercel/path0/pages/operator/users/[id].vue"),
    children: [
  {
    name: "operator-users-id-history___ua",
    path: "history",
    meta: historybmvCryRPBpMeta || {},
    component: () => import("/vercel/path0/pages/operator/users/[id]/history.vue")
  },
  {
    name: "operator-users-id___ua",
    path: "",
    component: () => import("/vercel/path0/pages/operator/users/[id]/index.vue")
  }
]
  },
  {
    name: "operator-users-clients___cs",
    path: "/operator/users/clients",
    meta: clientsqPtet9GLkIMeta || {},
    component: () => import("/vercel/path0/pages/operator/users/clients.vue")
  },
  {
    name: "operator-users-clients___ua",
    path: "/ua/operator/users/clients",
    meta: clientsqPtet9GLkIMeta || {},
    component: () => import("/vercel/path0/pages/operator/users/clients.vue")
  },
  {
    name: "operator-users-operators___cs",
    path: "/operator/users/operators",
    meta: operatorsCekn0CvH9eMeta || {},
    component: () => import("/vercel/path0/pages/operator/users/operators.vue")
  },
  {
    name: "operator-users-operators___ua",
    path: "/ua/operator/users/operators",
    meta: operatorsCekn0CvH9eMeta || {},
    component: () => import("/vercel/path0/pages/operator/users/operators.vue")
  },
  {
    name: "operator-users-organizations___cs",
    path: "/operator/users/organizations",
    meta: organizationsyeBjGnoRDHMeta || {},
    component: () => import("/vercel/path0/pages/operator/users/organizations.vue")
  },
  {
    name: "operator-users-organizations___ua",
    path: "/ua/operator/users/organizations",
    meta: organizationsyeBjGnoRDHMeta || {},
    component: () => import("/vercel/path0/pages/operator/users/organizations.vue")
  },
  {
    name: "register___cs",
    path: "/register",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue")
  },
  {
    name: "register___ua",
    path: "/ua/register",
    meta: registerIikvtlfpbHMeta || {},
    component: () => import("/vercel/path0/pages/register.vue")
  },
  {
    name: "requests-id-chat___cs",
    path: "/requests/:id()/chat",
    meta: chat5ptnKUH9RxMeta || {},
    component: () => import("/vercel/path0/pages/requests/[id]/chat.vue")
  },
  {
    name: "requests-id-chat___ua",
    path: "/ua/requests/:id()/chat",
    meta: chat5ptnKUH9RxMeta || {},
    component: () => import("/vercel/path0/pages/requests/[id]/chat.vue")
  },
  {
    name: "requests-id-edit___cs",
    path: "/requests/:id()/edit",
    meta: editopurr0m6VcMeta || {},
    component: () => import("/vercel/path0/pages/requests/[id]/edit.vue")
  },
  {
    name: "requests-id-edit___ua",
    path: "/ua/requests/:id()/edit",
    meta: editopurr0m6VcMeta || {},
    component: () => import("/vercel/path0/pages/requests/[id]/edit.vue")
  },
  {
    name: "requests-id-reserved___cs",
    path: "/requests/:id()/reserved",
    meta: reservedsmaNP8tzI0Meta || {},
    component: () => import("/vercel/path0/pages/requests/[id]/reserved.vue")
  },
  {
    name: "requests-id-reserved___ua",
    path: "/ua/requests/:id()/reserved",
    meta: reservedsmaNP8tzI0Meta || {},
    component: () => import("/vercel/path0/pages/requests/[id]/reserved.vue")
  },
  {
    name: "requests-id-waiting___cs",
    path: "/requests/:id()/waiting",
    meta: waitingAdl7gszxPXMeta || {},
    component: () => import("/vercel/path0/pages/requests/[id]/waiting.vue")
  },
  {
    name: "requests-id-waiting___ua",
    path: "/ua/requests/:id()/waiting",
    meta: waitingAdl7gszxPXMeta || {},
    component: () => import("/vercel/path0/pages/requests/[id]/waiting.vue")
  },
  {
    name: "requests-create___cs",
    path: "/requests/create",
    meta: createaxMMoyOkTNMeta || {},
    component: () => import("/vercel/path0/pages/requests/create.vue")
  },
  {
    name: "requests-create___ua",
    path: "/ua/requests/create",
    meta: createaxMMoyOkTNMeta || {},
    component: () => import("/vercel/path0/pages/requests/create.vue")
  },
  {
    name: "requests___cs",
    path: "/requests",
    meta: indexfWenljGIIYMeta || {},
    component: () => import("/vercel/path0/pages/requests/index.vue")
  },
  {
    name: "requests___ua",
    path: "/ua/requests",
    meta: indexfWenljGIIYMeta || {},
    component: () => import("/vercel/path0/pages/requests/index.vue")
  },
  {
    name: "settings___cs",
    path: "/settings",
    meta: settingsyZZu4Rd1aAMeta || {},
    component: () => import("/vercel/path0/pages/settings.vue"),
    children: [
  {
    name: "settings-type___cs",
    path: ":type()",
    meta: _91type_93RvJNWuMHnSMeta || {},
    component: () => import("/vercel/path0/pages/settings/[type].vue")
  },
  {
    name: "settings-code-push___cs",
    path: "code-push",
    meta: code_45pushqElbF41KaAMeta || {},
    component: () => import("/vercel/path0/pages/settings/code-push.vue")
  },
  {
    name: "settings-employees___cs",
    path: "employees",
    meta: employeesjJ4ocjYDWFMeta || {},
    component: () => import("/vercel/path0/pages/settings/employees.vue")
  }
]
  },
  {
    name: "settings___ua",
    path: "/ua/settings",
    meta: settingsyZZu4Rd1aAMeta || {},
    component: () => import("/vercel/path0/pages/settings.vue"),
    children: [
  {
    name: "settings-type___ua",
    path: ":type()",
    meta: _91type_93RvJNWuMHnSMeta || {},
    component: () => import("/vercel/path0/pages/settings/[type].vue")
  },
  {
    name: "settings-code-push___ua",
    path: "code-push",
    meta: code_45pushqElbF41KaAMeta || {},
    component: () => import("/vercel/path0/pages/settings/code-push.vue")
  },
  {
    name: "settings-employees___ua",
    path: "employees",
    meta: employeesjJ4ocjYDWFMeta || {},
    component: () => import("/vercel/path0/pages/settings/employees.vue")
  }
]
  },
  {
    name: "support___cs",
    path: "/support",
    meta: indexoa8UIM335lMeta || {},
    component: () => import("/vercel/path0/pages/support/index.vue")
  },
  {
    name: "support___ua",
    path: "/ua/support",
    meta: indexoa8UIM335lMeta || {},
    component: () => import("/vercel/path0/pages/support/index.vue")
  }
]