<template>
  <el-dialog
    :model-value="props.open"
    :title="props.title"
    :width="props.width"
    :fullscreen="props.fullscreen"
    :append-to-body="props.appendToBody"
    :modal="props.modal"
    :top="props.top"
    :close-on-click-modal="props.closeOnClickModal"
    :close-on-press-escape="props.closeOnPressEscape"
    :show-close="props.showClose"
    @close="emit('dismiss')"
  >
    <template #header>
      <div class="DialogTitle tw-w-full">
        <slot name="header" />
      </div>
    </template>
    <div class="tw-px-6">
      <slot name="content" />
    </div>
    <slot name="footer">
      <span
        v-loading="props.isLoading"
        class="DialogFooter tw-flex tw-items-center tw-justify-between"
      >
        <el-button
          class="el-button--no-shadow"
          @click="emit('dismiss')"
        >
          {{ props.dismissText }}
        </el-button>
        <el-button
          v-if="!props.dismissOnly"
          :loading="props.isAccepting"
          :disabled="!props.acceptEnabled || props.isAccepting"
          :type="props.acceptButtonType"
          data-cy="base-dialog-accept"
          @click="emit('accept')"
        >
          {{ props.acceptText }}
        </el-button>
      </span>
    </slot>
  </el-dialog>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  open: {
    type: Boolean,
    default: false,
  },
  dismissOnly: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    required: true,
  },
  appendToBody: {
    type: Boolean,
    default: false,
  },
  modal: {
    type: Boolean,
    default: true,
  },
  closeOnClickModal: {
    type: Boolean,
    default: true,
  },
  closeOnPressEscape: {
    type: Boolean,
    default: true,
  },
  showClose: {
    type: Boolean,
    default: true,
  },
  acceptEnabled: {
    type: Boolean,
    default: true,
  },
  fullscreen: {
    type: Boolean,
    default: false,
  },
  acceptText: {
    type: String,
    default: 'Potvrdit',
  },
  dismissText: {
    type: String,
    default: 'Zrušit',
  },
  acceptButtonType: {
    type: String,
    default: 'primary',
  },
  top: {
    type: String,
    default: '12vh',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  isAccepting: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['accept', 'dismiss']);
</script>

<style scoped>
.DialogFooter {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
}
</style>
