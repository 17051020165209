export const state = () => ({
  EMERGENCY_TYPE_ID: 4,
  types: [
    {
      id: 4,
      name: 'SOS',
      icon: 'icon-emergency',
      color: 'danger',
      lang: 'cs',
    },
    {
      id: 1,
      name: 'Úřad/Lékař',
      icon: 'icon-hospital',
      color: 'secondary',
      lang: 'cs',
    },
    {
      id: 2,
      name: 'Důležité',
      icon: 'icon-warning',
      color: 'tertiary',
      lang: 'cs',
    },
    {
      id: 3,
      name: 'Ostatní',
      icon: 'icon-coffee',
      color: 'green',
      lang: 'cs',
    },
    {
      id: 5,
      name: 'Лікар',
      nameTooltip: 'Lékař',
      lang: 'ua',
    },
    {
      id: 6,
      name: 'Поліція',
      nameTooltip: 'Policie',
      lang: 'ua',
    },
    {
      id: 7,
      name: 'Робота',
      nameTooltip: 'Práce',
      lang: 'ua',
    },
    {
      id: 8,
      name: 'Банк',
      nameTooltip: 'Banka',
      lang: 'ua',
    },
    {
      id: 9,
      name: 'Уряд',
      nameTooltip: 'Úřad',
      lang: 'ua',
    },
    {
      id: 10,
      name: 'Школа',
      nameTooltip: 'Škola',
      lang: 'ua',
    },
    {
      id: 11,
      name: 'Сімейні справи',
      nameTooltip: 'Rodinné záležitosti',
      lang: 'ua',
    },
    {
      id: 12,
      name: 'Магазини',
      nameTooltip: 'Obchody',
      lang: 'ua',
    },
    {
      id: 13,
      name: 'Послуги (електрика, вода, телефон ...)',
      nameTooltip: 'Služby (elektřina, voda, telefon...)',
      lang: 'ua',
    },
    {
      id: 14,
      name: 'Житло',
      nameTooltip: 'Bydlení',
      lang: 'ua',
    },
    {
      id: 15,
      name: 'Інше',
      nameTooltip: 'Jiné',
      lang: 'ua',
    },
  ],
  transcriptionTypes: [
    {
      id: 1,
      name: 'Přepis hovoru',
      title: 'Přepis telefonního hovoru',
      description: 'Úplný přepis telefonního hovoru. Během telefonátu se slyšící stranou vy píšete a přepisovatel mluví za vás a přepisuje odpovědi volaného.',
      youtube: 'TODO',
      icon: 'icon-transcript',
      color: 'tertiary',
    },
    {
      id: 2,
      name: 'Částečný přepis',
      title: 'Částečný přepis telefonního hovoru',
      description: 'Audio spojení se slyšící stranou během telefonátu. S volaným mluvíte přímo vy, přepisovatel vytočí číslo a v textovém okně přepisuje, co říká volaný. Odpovídáte vy, mluvíte.',
      youtube: 'TODO',
      icon: 'icon-user-voice',
      color: 'tertiary',
    },
    {
      id: 3,
      name: 'Osobní hovor',
      title: 'Přepis osobního hovoru',
      description: 'Audio spojení přepisovatele s vaším zařízením. Komunikace mezi vámi a slyšící stranou je přepisována na obrazovce zařízení. Vhodné např. na úřadě, u doktora, v lékárně.',
      youtube: 'TODO',
      icon: 'icon-mic',
      color: 'tertiary',
    },
    {
      id: 4,
      name: 'Úprava textu',
      title: 'Úprava textu',
      description: 'Přepisovatel stylististicky a gramaticky upraví zaslaný text.',
      youtube: 'TODO',
      icon: 'icon-edit',
      color: 'tertiary',
    },
  ],
  states: [
    {
      id: 1,
      name: 'Nový',
    },
    {
      id: 2,
      name: 'Právě vyřizovaný',
    },
    {
      id: 3,
      name: 'Pozastavený',
    },
    {
      id: 4,
      name: 'Dokončený',
    },
    {
      id: 5,
      name: 'Zrušeno',
    },
  ],
  services: [
    {
      id: 1,
      name: 'Tlumočení',
      color: 'secondary',
      icon: 'icon-interpreting',
      iconXl: 'icon-interpreting-xl',
    },
    {
      id: 2,
      name: 'Přepis',
      color: 'tertiary',
      icon: 'icon-transcript',
      iconXl: 'icon-transcript-xl',
    },
    {
      id: 3,
      name: 'Relay tlumočení UZJ',
      color: 'primary',
      icon: 'icon-group',
      iconXl: 'icon-group-xl',
    },
  ],
});

export const mutations = {
  REQUEST_TYPES_SET(state, payload) {
    state.types = payload;
  },
  REQUEST_SERVICES_SET(state, payload) {
    state.services = payload;
  },
  REQUEST_STATES_SET(state, payload) {
    state.states = payload;
  },
};

export const actions = {
  async getRequestTypes({ commit }) {
    try {
      const response = await useApi.get('client/requests/types');
      commit('REQUEST_TYPES_SET', response.results);
    } catch (error) {
      console.error(error);
    }
  },
  async getRequestStates({ commit }) {
    try {
      const response = await useApi.get('client/requests/states');
      commit('REQUEST_STATES_SET', response.results);
    } catch (error) {
      console.error(error);
    }
  },
  async cancelRequest({ commit }, requestId) {
    try {
      await useApi.delete(`client/requests/${requestId}`);
      commit('REQUEST_REMOVE', requestId);
      ElMessage.success('Požadavek byl úspěšně odstraněn.');
    } catch (error) {
      ElMessage.error('Požadavek se nepodařilo odstranit.');
      console.error(error);
    }
  },
};

export const getters = {
  getRequestService: (state) => (id) => {
    return state.services.find((item) => item.id == id);
  },
  getRequestsType: (state) => (id) => {
    return state.types.find((item) => item.id == id);
  },
  getTranscriptionType: (state) => (id) => {
    return state.transcriptionTypes.find((item) => Number(item.id) === Number(id));
  },
  getRequestTypesExceptEmergency: (state) => {
    return state.types.filter((type) => type.id !== state.EMERGENCY_TYPE_ID);
  },
  getType: (state) => (id) => {
    return state.types.find((type) => type.id == id);
  },
  getTypes: (state) => (lang) => {
    return lang ? state.types.filter((type) => type.lang == lang) : state.types;
  },
};
